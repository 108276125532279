@tailwind base;
@tailwind components;
@tailwind utilities;

/* styles/global.css */
/* @font-face {
  font-family: 'Saira';
  src: url('/fonts/SairaCondensed-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

*/

@font-face {
  font-family: 'dmsans';
  src: url('/files/fonts/DMSans.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
} 


:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}
/* App.css */

html{
  min-height: 100%;
  width: 100%;
}

body {
  color: black;
  background-color: #F7F7FB;
  min-height: 100%;
  font-family:  'dmsans';
  min-width: 320px;
  -webkit-font-smoothing: subpixel-antialiased !important; 
  text-rendering:optimizeLegibility !important;

  /* Additional styles for your content */
}

.inter{
  font-family: 'dmsans';
}

nav{
  border-radius: 0px !important;
}